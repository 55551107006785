@import "custom-bs-import";

table .sort {
    cursor: pointer;
}

table .sort:hover {
    --#{$variable-prefix}table-accent-bg: var(--#{$variable-prefix}table-hover-bg);
    color: var(--#{$variable-prefix}table-hover-color);
}

table .sort:after {
    font-family: 'bootstrap-icons';
    content: '\f283'; // chevron-expand
    padding-left: .25em;
    padding-right: .25em;
}

table .sort.asc:after {
    content: '\f282'; // chevron-down
}

table .sort.desc:after {
    content: '\f286'; // chevron-up
}
